import React, { Component } from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Link } from "gatsby"
import Company from "../components/danismanlik-company"
import rightArrow from "../assets/img/right-arrow.png";

import marka from "../assets/img/yenigifler.gif"
import surec1 from "../assets/img/landingbuilder-1.png"
import surec2 from "../assets/img/landingbuilder-2.png"
import surec3 from "../assets/img/landingbuilder-3.png"
import surec4 from "../assets/img/landingbuilder-4.png"
import surec5 from "../assets/img/landingbuilder-5.png"
import surec6 from "../assets/img/right.png"
import surec7 from "../assets/img/term1.png"
import surec8 from "../assets/img/term2.png"
import surec9 from "../assets/img/term3.png"
import term1 from "../assets/img/term4.png"
import term2 from "../assets/img/term5.png"
import term3 from "../assets/img/term6.png"
import smgif from "../assets/img/smgif.gif"
import filtregif from "../assets/img/filtregif.gif"
import nkl from "../assets/img/nklnew.png"
import landingbuilder from "../assets/img/landingbuilder-icerik.png"
import checkModal from "../assets/img/check-modal.png";



class SocialMedia extends Component {
    constructor(props) {
        super(props);

        this.state = {
            display: false,
            value: ''
        };
        this.handleChange = this.handleChange.bind(this);
    }
    handleChange(e) {
        e.preventDefault();

        this.setState({ value: e.target.value });
    }
    showModal() {
        this.setState({ display: !this.state.display });
        document.querySelector('.check-modal').classList.add('active-modal');
    }
    closeModal() {
        this.setState({ display: !this.state.display })
        document.querySelector('.check-modal').classList.remove('active-modal');
    }
    handleSubmit(e) {
        e.preventDefault();
        const title = document.title.split('|', 1).toString();
        const response = fetch('https://forms.flatart.com.tr/index.php?r=app%2Ff&id=7', {
            method: 'POST',
            body: JSON.stringify({
                "text_327649": title,
                "text_204202": document.querySelector('#site').value,
                "text_775908": document.querySelector('#name').value,
                "text_532192": document.querySelector('#email').value,
            }),
            headers: {
                "Content-type": "application/json; charset=UTF-8"
            }
        });
        response.then(res => {
            if (res.status === 200) {
                document.querySelector('.status').innerHTML = "Mesajınız başarıyla gönderilmiştir.";
            }
            else {
                document.querySelector('.status').innerHTML = "Bir hata oluştu.";
            }
        });
    }
    render() {
        return (
            <Layout>
                <SEO title="MyLandingBuilder " description="Effortlessly design captivating and conversion-optimized landing pages for your website using MyLandingBuilder's intuitive tools." />
                <div className="seo-service container">
                    <div className="seo-service__head">
                        <div className="col-md-6 noPadding">
                            <h1 className="landingbuilerder-head">MyLandingBuilder</h1><br></br>
                            <span className="red-head" style={{ fontSize:24}}>Create Stunning Landing Pages<br></br> Without Any Code Knowledge!</span>
                            <p style={{ maxWidth: 550 }} className="arfiltre news" >
Are you ready to take your online presence to the next level? </p>
<p style={{ maxWidth: 550 }} className="arfiltre" >Introducing MyLandingBuilder – the ultimate tool for effortlessly designing captivating landing pages without the need for any coding expertise. Whether you're a seasoned marketer, a small business owner, or an aspiring entrepreneur, MyLandingBuilder empowers you to showcase your products, services, or ideas in a visually stunning and professional manner. Say goodbye to the complexities of coding and say hello to creativity and simplicity with MyLandingBuilder!
</p>
<input type="text" className="pink-input" id="email-marketing-input" placeholder="Enter your e-mail address" value={this.state.value} onChange={this.handleChange} />
                                    <button className="red-button" id="email-marketing-button" onClick={(e) => this.showModal()}>Get your special offer</button>
                        </div>
                        <div className="col-md-6" style={{ marginTop: 33.5, paddingLeft: 50 }}><img alt="alt text" src={landingbuilder} /> </div>
                        <div className="check-modal">
                            <span onClick={(e) => this.closeModal()}>X</span>
                            <h2 className="form-head">Fill the form</h2>                               <p className="form-desc">Enter your information in the fields to get information, and we will get back to you as soon as possible.</p>
                            <div className="check-modal_container">
                            <form onSubmit={this.handleSubmit} >
                                    <input type="text" name="text_204202" id="site" required  placeholder="Enter your website." />
                                    <input type="text" id="name" required name="text_775908" placeholder="Enter your name" />
                                    <input type="email" id="email" required  name="text_532192" value={this.state.value} onChange={this.handleChange} autoFocus={true} placeholder="Enter yor email" />
                                    <button  >SEND</button>
                                    <div className="status"></div>
                                </form>
                                <img alt="alt text" src={checkModal} />
                            </div>
                        </div>
                       
                    </div>
                </div>
                <div className="container brands_content">
                    <h2 style={{ color: '#ea4f6a' }}>
                    What is MyLandingBuilder?
                    </h2>
                    <p className="arfiltre">MyLandingBuilder is a revolutionary platform designed to enable anyone, regardless of their technical background, to craft impressive landing pages that convert. With a user-friendly interface and an array of powerful features at your fingertips, MyLandingBuilder eliminates the barriers to creating high-quality landing pages. Our tool streamlines the entire process, making it accessible and enjoyable for beginners and experts alike.</p>

                </div>

                <div className="onboarding container py60 news">
                <div className="col-md-6 landingleft">
                        <img style={{ minWidth: 267 }} alt="alt text" src={surec1}></img>
                    </div>

                    <div className="col-md-6 landing">
                    <h3 style={{ color: '#ea4f6a' }}>
                    Drag-and-Drop Interface
                    </h3>
                        <p className="arfiltre">MyLandingBuilder's intuitive drag-and-drop interface lets you effortlessly arrange elements on your landing page. No coding required – simply select elements like images, text, buttons, forms, and more, then position them precisely where you want them.</p>
                    </div>
                    

                </div>
                <div className="onboarding container news builder">
                    <div className="col-md-6 landing news">
                    <h3 style={{ color: '#ea4f6a' }} className="h3head">
                    Customizable Templates
                    </h3>
                        <p className="arfiltre builder">Choose from a collection of professionally designed templates crafted to suit various industries and purposes. Personalize these templates with your branding, content, and color scheme to match your vision perfectly.
                        </p>
                    </div>
                    <div className="col-md-6 landingleft">
                        <img style={{ minWidth: 318 }} alt="alt text" src={surec2}></img>
                    </div>

                </div>
                <div className="onboarding container py60 late">
                <div className="col-md-6 landingleft">
                        <img style={{ minWidth: 255 }} alt="alt text" src={surec3}></img>
                    </div>

                    <div className="col-md-6 landing">
                    <h3 style={{ color: '#ea4f6a' }}>
                    Mobile Responsive
                    </h3>
                        <p className="arfiltre">Ensure your landing pages look stunning on every device with our mobile-responsive design. MyLandingBuilder automatically adapts your page's layout to provide a seamless experience for visitors on smartphones, tablets, and desktops.</p>
                    </div>
                    

                </div>
                <div className="onboarding container news builder">
                    <div className="col-md-6 landing news">
                    <h3 style={{ color: '#ea4f6a' }} className="h3head">
                    Integrated Media Library
                    </h3>
                        <p className="arfiltre builder">
Access a comprehensive media library packed with high-resolution images, icons, and graphics. Effortlessly enhance your landing page with visually appealing elements that capture your audience's attention.
                        </p>
                    </div>
                    <div className="col-md-6 landingleft">
                        <img style={{ minWidth: 369 }} alt="alt text" src={surec4}></img>
                    </div>

                </div>

                <div className="onboarding container py60 late">
                <div className="col-md-6 landingleft">
                        <img style={{ minWidth: 255 }} alt="alt text" src={surec5}></img>
                    </div>

                    <div className="col-md-6 landing">
                    <h3 style={{ color: '#ea4f6a' }}>
                    Conversion-Optimized Elements
                    </h3>
                        <p className="arfiltre">Drive action with strategically placed call-to-action buttons, lead capture forms, countdown timers, and more. MyLandingBuilder equips you with the tools you need to maximize conversions and achieve your goals.</p>
                    </div>
                </div>
                <div className="container manage-google builder" style={{ padding: '40px 60px' }}>
                    <h2 className="text">How to Use MyLandingBuilder</h2>
                    <p className="manage-google_p">Creating a stunning landing page with MyLandingBuilder is a breeze! Follow these simple steps to get started:</p>
                    <div>
                        <div className="col-md-6 manage-google_item">
                            <img alt="alt text" src={surec7} />
                            <div>
                                <h3 className="text">Sign Up</h3>
                                <p className="builder-news">Register for a MyLandingBuilder account. Don’t forget, MyLandingBuilder is totally free!</p>
                            </div>
                        </div>
                        <div className="col-md-6 manage-google_item">
                            <img alt="alt text" src={surec8} />
                            <div>
                                <h3 className="text">Edit and Customize</h3>
                                <p className="builder-news">Use the drag-and-drop editor to modify the template. Add your content, tweak colors, upload images, and fine-tune every aspect to match your brand identity.</p>
                            </div>
                        </div>
                        <div className="col-md-6 manage-google_item">
                            <img alt="alt text" src={surec9} />
                            <div>
                                <h3 className="text">Select a Template</h3>
                                <p className="builder-news">Browse our library of customizable templates. Choose the one that resonates with your project's goals and style.</p>
                            </div>
                        </div>
                        <div className="col-md-6 manage-google_item">
                            <img alt="alt text" src={term1} />
                            <div>
                                <h3 className="text">Add Elements</h3>
                                <p className="builder-news">Enhance your landing page's functionality by adding elements such as forms, buttons, images, videos, and more. Simply drag them onto the page and position them as you like.</p>
                            </div>
                        </div>
                        <div className="col-md-6 manage-google_item builderss">
                            <img alt="alt text" src={term2} />
                            <div>
                                <h3 className="text">Preview and Publish</h3>
                                <p className="builder-news">Before making your landing page live, preview it to ensure everything looks perfect. Once satisfied, hit the publish button to share your creation with the world.</p>
                            </div>
                        </div>
                       
                    </div>
                </div>


                <div className="checkup checkup-index py60 container" >
                <div className="col-md-6">
                    <h2 className="check-head news">Boost Your Conversions<br></br> with MyLandingBuilder!</h2>
                    <p>You no longer need to struggle with coding or spend exorbitant amounts on hiring developers. MyLandingBuilder empowers you to bring your ideas to life, captivate your audience, and drive meaningful results – all without any prior coding knowledge. Start building eye-catching landing pages today and take your online presence to new heights with MyLandingBuilder!</p>

<Link  className="landingbuilders" to="https://mylandingbuilder.com/">Try it for free</Link>
                </div>
                <div className="col-md-6">
                    <img alt="alt text" src={term3} />
                </div>
                
            </div>





            </Layout>
        )
    }
}
export default SocialMedia
